
import { Notification } from "@/entities/notification";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import { getLearning } from "@/api/learning";
import { Learning } from "@/entities/learning";

@Options({
  components: {
    MIcon
  },
  emits: ["check", "select", "confirm"],
  props: {
    item: Object,
    selected: Boolean
  }
})
export default class MNNotificationItem extends Vue {
  item!: Notification;
  learning: Learning | null = null;
  selected = false;

  async mounted() {
    if (this.item.data.learningRef)
      this.learning = await getLearning(this.item.data.learningRef);
  }

  get isImageMessage() {
    return this.item.data.type === "get_image_message";
  }
  get isSubmission() {
    return (
      this.item.data.type === "post_submission" && this.item.data.submissionRef
    );
  }
  get isThread() {
    return (
      this.item.data.type === "post_submission" && this.item.data.threadRef
    );
  }

  get hasReflectionImages() {
    return !!this.learning?.data.reflection?.images.length;
  }

  get timeText(): string {
    const now = dayjs().locale("ja");
    const notificationTime = dayjs(this.item.data.timestamp * 1000).locale(
      "ja"
    );
    if (now.year() !== notificationTime.year()) {
      return notificationTime.format("YYYY/M/D HH:mm");
    } else if (
      now.month() === notificationTime.month() &&
      now.date() === notificationTime.date()
    ) {
      return "今日 " + notificationTime.format("HH:mm");
    } else if (
      now.month() === notificationTime.month() &&
      now.date() === notificationTime.date() + 1
    ) {
      return "昨日 " + notificationTime.format("HH:mm");
    } else {
      return notificationTime.format("M/D HH:mm");
    }
  }

  get isStudentCategory() {
    return (
      this.item.data.type === "get_message" ||
      this.item.data.type === "get_image_message" ||
      this.item.data.type === "enter_room" ||
      this.item.data.type === "leave_room" ||
      this.item.data.type === "start_timer" ||
      this.item.data.type === "end_timer" ||
      this.item.data.type === "post_reflection" ||
      this.item.data.type === "post_reflection_of_timer" ||
      this.item.data.type === "post_submission" ||
      this.item.data.type === "obtain_badge"
    );
  }

  get categoryName(): string {
    if (this.isStudentCategory) {
      return "生徒";
    } else if (this.item.data.type === "announce") {
      return "お知らせ";
    } else if (this.item.data.type === "other") {
      return "その他";
    } else {
      return "";
    }
  }

  get categoryPath(): string {
    if (this.isStudentCategory) {
      return "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z";
    } else if (this.item.data.type === "announce") {
      return "M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z";
    } else if (this.item.data.type === "other") {
      return "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z";
    } else {
      return "";
    }
  }

  get dynamicSectionCss() {
    if (this.selected) {
      return "bg-gray-200 border-2 border-secondary-500 rounded-md";
    } else {
      return "bg-white hover:bg-gray-200 border-b border-gray-100";
    }
  }

  get dynamicContentCss() {
    if (this.selected) {
      return "bg-white";
    } else {
      return "bg-gray-100";
    }
  }

  async openSubmission() {
    const id = this.item.data.submissionRef?.id;
    if (!id) {
      return;
    }
    const url = `/submission/print?id=${id}`;
    window.open(url, "_blank");
  }

  async openThread() {
    const id = this.item.data.threadRef?.id;
    if (!id) {
      return;
    }
    const url = `/thread/print?id=${id}`;
    window.open(url, "_blank");
  }

  openImage() {
    window.open(this.item.data.content, "_blank");
  }

  async openHistory() {
    const student = this.item.data.user;
    if (student.parent.id !== "students") {
      return;
    }
    const classroomRef = student.parent.parent;
    if (!classroomRef) {
      return;
    }
    const schoolRef = classroomRef.parent.parent;
    if (!schoolRef) {
      return;
    }
    const learningRef = this.learning?.ref;
    if (!learningRef) {
      return;
    }
    const url = `/student/${schoolRef.id}/${classroomRef.id}/${student.id}/history/${learningRef.id}`;
    window.open(url, "_blank");
  }

  confirm() {
    this.$emit("confirm");
  }
  check() {
    this.$emit("check");
  }
  select() {
    this.$emit("select");
  }
}
